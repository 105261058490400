@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,700|Roboto:300,400,700|Sen:400,700&display=swap);
* {
  box-sizing: border-box

}

html, body {
  margin: 0;
  /* block horizontal scrolling */
  overflow-x: hidden;
}

html {
  background-color: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}

#btn {
  padding: 5px 20px;
  font-size: 13px;
  border-radius: 5px;
  font-family: "Poppins", "Roboto", sans-serif;
}

.main-button {
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: #ffa600;
  color: white;
}

.light-button {
  border: 1px solid #ffa600;
  background-color: white;
  color: #ffa600;
}

.blue-button {
  background-color: #2f4dc4;
  color: white;
}

.disabled-button {
  background-color: #dce3eb;
  cursor: no-drop;
}

@media (max-width: 890px) {
  #btn {
    font-size: 0.8em;
  }
}
/*# sourceMappingURL=Button.css.map */
.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}

figure {
  font-family: "Poppins", "Roboto", sans-serif;
  position: relative;
  float: left;
  overflow: hidden;
  margin: 0;
  color: white;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
}

figure .image {
  position: relative;
  display: block;
  background-size: cover;
  width: calc(100% + 15px);
  overflow: hidden;
  transition: opacity 0.35s, transform 0.35s;
  transform: translate3d(-10px, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

figure .grid-item-overlay, figure figcaption {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

figure figcaption {
  text-align: left;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 10px 30px;
}

figure h2 {
  position: relative;
  overflow: hidden;
  padding: 0.5em 0;
  font-size: 1.7em;
  font-weight: lighter;
  opacity: 1;
}

figure h2::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
  height: 1px;
  background: #fff;
  content: '';
  transition: transform 0.5s;
  transform: translate3d(-90%, 0, 0);
}

figure p {
  margin-top: 20%;
  width: 90%;
  font-weight: 500;
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  transform: translate3d(100%, 0, 0);
}

figure .read-more {
  color: #fff;
}

figure:hover .image {
  transform: translate3d(0, 0, 0);
}

figure:hover h2::after {
  transform: translate3d(0, 0, 0);
}

figure:hover p {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.read-more:hover {
  text-decoration: underline;
}

@media (max-width: 1100px) {
  figure h2 {
    font-size: 1.2em;
  }
  figure p {
    font-size: 0.8em;
  }
}

@media (max-width: 900px) {
  figure h2 {
    font-size: 1em;
  }
  figure p {
    font-size: 0.7em;
    line-height: 1.8em;
    margin-top: 10%;
    width: 100%;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 500px) {
  figure h2 {
    font-size: 0.9em;
  }
  figure p {
    display: none;
  }
  figure .show-text-on-mobile {
    display: block;
  }
  figure figcaption {
    padding: 0 20px;
  }
}

@media (max-width: 400px) {
  figure h2 {
    font-size: 0.75em;
    margin-bottom: 0;
  }
  figure p {
    margin-top: 7px;
    font-size: 0.4em;
  }
}
/*# sourceMappingURL=GridItem.css.map */
.main-container{transform:skewY(3deg);background-color:white}.main-content{transform:skewY(-3deg)}*{box-sizing:border-box}button{border:none;cursor:pointer}a{text-decoration:none;color:#000}img{display:block}li{list-style:none}footer{position:relative;text-align:center;width:100%;color:#fff;padding:20px 40px;padding-top:30px;font-family:"Poppins","Roboto",sans-serif;background-color:#282f40;z-index:70;font-size:0.8em;display:flex;justify-content:space-between}.active-link{font-weight:bold}.main-container{margin-top:-60px;padding:80px 10%}@keyframes fadein{from{opacity:0}to{opacity:1}}@keyframes fadeout{from{opacity:1}to{opacity:0}}@keyframes slidedown{from{transform:translate3d(-100%, 0, 0)}to{transform:translate3d(0, 0, 0)}}@media (max-width: 768px){.main-container h1{font-size:1.5em}footer{font-size:0.7em}}@media (max-width: 600px){footer{flex-direction:column}}@media (min-width: 1900px){.main-container{transform:skewY(4deg)}.main-content{transform:skewY(-4deg)}}

.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}
/*# sourceMappingURL=App.css.map */
.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

#home-header-container {
  display: flex;
  flex-wrap: wrap;
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}

#home-header-container {
  background: url(/static/media/bg-light-full.2272cc82.jpg) no-repeat;
  background-position-y: 30px;
  background-size: cover;
  width: 100%;
  color: #fff;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  overflow: hidden;
}

#home-header-container #home-header-overlay {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

#home-header-container .home-header-text {
  margin: 17% 10%;
  font-family: "Poppins", "Roboto", sans-serif;
  font-weight: lighter;
}

#home-header-container .home-header-text h1,
#home-header-container .home-header-text h3 {
  display: block;
  font-weight: 500;
  text-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
}

#home-header-container .home-header-text h1 {
  width: 55%;
  font-size: 2.7rem;
}

#home-container #what-we-do-box {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: whatwedo;
}

#home-container #who-we-are-box {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: whoweare;
}

#home-container #journal-box {
  -ms-grid-row: 2;
  -ms-grid-row-span: 3;
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  grid-area: journal;
}

#home-container #our-works-box {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: works;
}

#home-container #opportunities-box {
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  grid-area: opportunities;
}

#home-container #platform-beta {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: platformbeta;
}

#home-container .grid-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: fit-content(20em) fit-content(5em) fit-content(8em) fit-content(8em);
      grid-template-rows: fit-content(20em) fit-content(5em) fit-content(8em) fit-content(8em);
      grid-template-areas: "whatwedo whatwedo whoweare" "platformbeta journal journal" "works journal journal" "opportunities journal journal";
  max-width: 1250px;
  margin: auto;
}

@media (max-width: 1100px) {
  #home-header-container .home-header-text h1 {
    width: 70%;
    font-size: 2.7rem;
  }
}

@media (max-width: 900px) {
  #home-header-container .home-header-text {
    margin: 12rem;
  }
  #home-header-container .home-header-text h1 {
    width: 100%;
    font-size: 2.7rem;
  }
}

@media (max-width: 800px) {
  #home-header-container .home-header-text {
    text-align: center;
  }
  #home-container .grid-container {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    -ms-grid-rows: 1fr 0.2fr 0.2fr 1fr;
        grid-template-rows: 1fr 0.2fr 0.2fr 1fr;
        grid-template-areas: "whatwedo whatwedo" "whoweare works" "platformbeta opportunities"   "journal journal";
  }
}

@media (max-width: 768px) {
  #home-header-container .home-header-text h1 {
    font-size: 2.2rem;
  }
  #home-header-container .home-header-text h3 {
    font-size: 1rem;
  }
}

@media (max-width: 640px) {
  #home-header-container .home-header-text {
    margin: 13rem 8rem;
  }
}

@media (max-width: 450px) {
  #home-header-container .home-header-text {
    margin: 12rem 5rem;
  }
  #home-header-container .home-header-text h1 {
    font-size: 2rem;
  }
}
/*# sourceMappingURL=Home.css.map */
.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

.header-container .header-text {
  display: flex;
  flex-wrap: wrap;
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}

.header-container {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: #fff;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  overflow-x: hidden;
}

.header-container .header-overlay {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10rem;
  background-size: 600% 600%;
  opacity: 0.95;
  animation: 10s ease 0s infinite normal none running colourTransition;
}

@keyframes colourTransition {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 51%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.header-container .header-text {
  flex-direction: row;
  justify-content: space-around;
  margin: 0;
  font-family: "Poppins", "Roboto", sans-serif;
  line-height: 2em;
}

.header-container .header-text h1 {
  font-size: 2.8rem;
  width: 100%;
  line-height: 1.3em;
  margin-right: 0;
  margin-top: 45%;
}

.header-container .header-content {
  width: 60%;
  text-align: justify;
  font-weight: 500;
  margin: auto;
}

.header-container .breadcrumbs {
  color: #fff;
}

@media (max-width: 1150px) {
  .header-container .header-overlay {
    padding: 7rem;
  }
}

@media (max-width: 1100px) {
  .header-container .header-text h1 {
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .header-container .header-text {
    flex-direction: column;
  }
  .header-container .header-text h1, .header-container .header-content {
    width: 100%;
  }
  .header-container .header-text h1 {
    margin-top: 50px;
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .header-container .header-overlay {
    padding: 7rem 4rem;
  }
  .header-container .header-text h1 {
    font-size: 2.2rem;
  }
  .header-container .header-content, .header-container .breadcrumbs {
    font-size: 0.8rem;
  }
}

@media (max-width: 500px) {
  .header-container .header-overlay {
    padding: 9rem 3rem;
  }
  .header-container .header-text h1 {
    font-size: 2rem;
  }
}
/*# sourceMappingURL=Header.css.map */
.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

.block-container {
  display: flex;
  flex-wrap: wrap;
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}

.block-container {
  overflow: hidden;
  font-family: "Poppins", "Roboto", sans-serif;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 40px;
}

.block-container .block-img {
  height: 400px;
  margin: 20px;
  margin-top: 20px;
}

.block-container img {
  height: 400px;
}

.block-container .block-text {
  width: 50%;
  text-align: left;
}

.block-container .block-text h4 {
  display: block;
  text-transform: uppercase;
  color: #d12d4c;
  margin-bottom: unset;
}

.block-container .block-text h2 {
  display: block;
  margin-top: 5px;
  font-weight: 400;
}

.block-container .block-content {
  line-height: 2em;
  font-weight: 300;
}

@media (max-width: 850px) {
  .block-container .block-content {
    font-size: 0.8rem;
  }
  .block-container .block-img, .block-container img {
    display: block;
    height: 300px;
  }
}

@media (max-width: 665px) {
  .block-container {
    display: block;
  }
  .block-container .block-img {
    width: 95%;
    height: 250px;
  }
  .block-container img {
    height: 250px;
    margin: auto;
  }
  .block-container .block-text {
    width: 100%;
    text-align: center;
    clear: both;
  }
  .block-container .block-text h2 {
    font-size: 1.2em;
  }
  .block-container .block-content {
    font-size: 0.75em;
  }
}
/*# sourceMappingURL=Block.css.map */
.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

#about-container .grid-container {
  display: flex;
  flex-wrap: wrap;
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}

#about-container #ivy {
  grid-area: ivy;
}

#about-container #goffy {
  grid-area: goffy;
}

#about-container #cat {
  grid-area: cat;
}

#about-container .grid-container {
  padding: 0;
  overflow: hidden;
  max-width: 1200px;
  margin: auto;
}
/*# sourceMappingURL=About.css.map */
.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

.card-container {
  display: flex;
  flex-wrap: wrap;
}

.card-container {
  text-align: left;
  line-height: 2em;
  font-size: 16px;
  font-family: "Poppins", "Roboto", sans-serif;
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}

.card-container {
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(220, 227, 235, 0.5);
  flex-direction: row;
  border: 1px solid rgba(220, 227, 235, 0.5);
  flex-wrap: nowrap;
  overflow: hidden;
}

.card-container .card-img {
  width: 100%;
  height: 300px;
}

.card-container .card-text {
  padding: 5% 10%;
  font-weight: 300;
  overflow: hidden;
  max-width: 1400px;
}

@media (max-width: 1024px) {
  .card-text {
    font-size: 0.9em;
  }
}
/*# sourceMappingURL=Card.css.map */
.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}

#services-container .main-content {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

#services-container #dubbing-card {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: dub;
}

#services-container #translation-card {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: translation;
}

#services-container #production-management-card {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: production;
}

#services-container #talent-card {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  grid-area: talent;
}

#services-container .grid-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "dub translation" "production talent";
  grid-gap: 20px;
  overflow: hidden;
}

@media (max-width: 768px) {
  #services-container .service-card {
    margin-bottom: 20px;
  }
  #services-container .grid-container {
    display: flex;
    flex-wrap: wrap;
  }
}
/*# sourceMappingURL=Services.css.map */
.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

#journal-container .category-list {
  display: flex;
  flex-wrap: wrap;
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}

#journal-container {
  padding: 40px 10%;
}

#journal-container .loader {
  font-family: "Lato", sans-serif;
}

#journal-container .category-list {
  justify-content: space-evenly;
  font-family: "Poppins", "Roboto", sans-serif;
  margin: 50px auto;
  font-weight: bold;
  padding: 0;
}

#journal-container .article-collections {
  margin: auto;
}

@media (max-width: 768px) {
  .article-collections {
    display: block;
  }
}

@media (min-width: 1700px) {
  #journal-container {
    padding: 40px 20%;
  }
}
/*# sourceMappingURL=Journal.css.map */
.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

.article-card-container {
  text-align: left;
  line-height: 2em;
  font-size: 16px;
  font-family: "Poppins", "Roboto", sans-serif;
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}

.article-card-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 80px;
}

.article-card-container .card-img {
  width: 50%;
  height: 300px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.article-card-container .card-text, .article-card-container .card-img {
  margin: 10px 15px;
}

.article-card-container .card-text {
  width: 50%;
  font-weight: 300;
  text-align: justify;
  overflow: hidden;
}

.article-card-container .card-text h6 {
  display: block;
  margin: 0;
}

.article-card-container .card-text h4 {
  text-transform: uppercase;
  color: #d12d4c;
  display: block;
  margin: 0;
}

.article-card-container .card-text h1 {
  position: relative;
  display: block;
  line-height: 1.3em;
  margin-top: 0;
  text-align: left;
}

.article-card-container:hover .card-text > h1 {
  color: #311885;
}

@media (max-width: 768px) {
  .article-card-container .card-text, .article-card-container .card-img {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .article-card-container .card-text h4 {
    font-size: 0.8em;
  }
  .article-card-container .card-img {
    height: 220px;
  }
}
/*# sourceMappingURL=ArticleCard.css.map */
.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content, .article-container .breadcrumbs {
  transform: skewY(-3deg);
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content, .article-container .breadcrumbs {
    transform: skewY(-4deg);
  }
}

.article-container {
  padding-top: 15rem;
  font-family: "Poppins", "Roboto", sans-serif;
  margin-bottom: 80px;
}

.article-container li {
  list-style-type: disc;
}

.article-container .breadcrumbs {
  font-size: 0.9em;
  margin-top: 8rem;
}

.article-container .article-header h4 {
  color: #d12d4c;
}

.article-container .article-header h1 {
  line-height: 1.5em;
}

.article-container .main-content {
  width: 65%;
  margin: auto;
  line-height: 2em;
  font-weight: 300;
}

.article-container .article-image {
  width: 100%;
}

.article-container .author-details {
  margin-bottom: 20px;
}

.article-container .author-image {
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

.article-container .article-content img {
  margin: auto;
  width: 100%;
}

.article-container .article-content a {
  color: #000080;
  text-decoration: underline;
}

.article-container .article-content a:visited,
.article-container .article-content a:active {
  color: #660099;
}

.article-container .quote {
  color: #999;
  font-size: 1.2em;
  font-style: italic;
  margin: 0;
  padding: .5em .5em .5em 2.5em;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 400 300'><polygon opacity='0.15' fill='currentColor' points='184,40 108,150 166,150 166,300 0,300 16,139 103,40'/><polygon opacity='0.15' fill='currentColor' points='216,260 292,150 234,150 234,0 400,0 384,161 297,260'/></svg>");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-origin: border-box;
  background-size: 2em;
}

@media (max-width: 1700px) {
  .article-container .main-content {
    width: 75%;
  }
}

@media (max-width: 1300px) {
  .article-container .main-content {
    width: 90%;
  }
}

@media (max-width: 1024px) {
  .article-container .article-content p,
  .article-container .article-content li {
    font-size: 14px;
  }
  .article-container .quote {
    font-size: 18px;
  }
}

@media (max-width: 820px) {
  .article-container .main-content {
    width: 100%;
  }
  .article-container .article-content p,
  .article-container .article-content li {
    font-size: 12px;
  }
}
/*# sourceMappingURL=Article.css.map */
.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}

#inquiry-container {
  margin-top: 50px;
  min-height: 85vh;
}

#inquiry-container .loading {
  width: 100%;
  text-align: center;
  color: #d12d4c;
  font-family: "Lato", sans-serif;
  font-weight: lighter;
  position: absolute;
  top: 25%;
  letter-spacing: 0.1em;
}

@media (min-height: 900px) {
  #inquiry-container {
    min-height: 100vh;
  }
}
/*# sourceMappingURL=IframeForm.css.map */
.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}

#opportunities-container {
  padding: 40px 10%;
}

#opportunities-container .loader {
  font-family: "Lato", sans-serif;
}

#opportunities-container .opportunities-collections {
  margin: auto;
}

@media (max-width: 1300px) {
  #opportunities-container {
    padding: 40px 60px;
  }
}

@media (max-width: 768px) {
  .opportunities-collections {
    display: block;
  }
}

@media (min-width: 1700px) {
  #opportunities-container {
    padding: 40px 20%;
  }
}
/*# sourceMappingURL=Opportunities.css.map */
.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  font-family: "Poppins", "Roboto", sans-serif;
}

.modal-overlay .modal-container {
  width: 30%;
  margin: auto;
  margin-top: 15%;
  background-color: white;
  padding: 20px 30px 40px 30px;
}

.modal-overlay h1 {
  text-align: center;
  margin-top: 5px;
}

.modal-overlay .exit-modal {
  font-size: 0.85em;
  cursor: pointer;
}
/*# sourceMappingURL=ModalContainer.css.map */
.main-container {
  transform: skewY(3deg);
  background-color: white;
}

.main-content {
  transform: skewY(-3deg);
}

#navbar, #navbar #main-nav ul {
  display: flex;
  flex-wrap: wrap;
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #282f40;
  z-index: 70;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.main-container {
  margin-top: -60px;
  padding: 80px 10%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidedown {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 768px) {
  .main-container h1 {
    font-size: 1.5em;
  }
  footer {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  footer {
    flex-direction: column;
  }
}

@media (min-width: 1900px) {
  .main-container {
    transform: skewY(4deg);
  }
  .main-content {
    transform: skewY(-4deg);
  }
}

#navbar {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  padding: 20px 70px 8px 70px;
  font-family: "Poppins", "Roboto", sans-serif;
  font-size: 14px;
  background-color: white;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.09);
}

#navbar #main-nav ul {
  flex-direction: row;
  justify-content: space-around;
  padding: 0;
}

#navbar #main-nav > ul > li {
  text-align: center;
  margin-right: 30px;
}

#navbar #logo-wrapper {
  margin-right: auto;
  width: 70px;
}

#navbar #logo {
  width: 100%;
}

#navbar .btn-container {
  margin-top: 8px;
}

#navbar .btn-container + .btn-container {
  margin-left: 15px;
}

@media (max-width: 890px) {
  #navbar {
    padding: 20px 10px 8px 10px;
    justify-content: center;
  }
  #navbar #main-nav ul {
    font-size: 0.8em;
  }
  #navbar .btn-container {
    margin-top: 5px;
    font-size: 0.8em;
  }
}

@media (max-width: 695px) {
  #navbar #logo-wrapper, #navbar #main-nav {
    margin: 0 auto 5px auto;
  }
  #navbar #logo-wrapper {
    width: 60px;
  }
  #navbar #main-nav > ul > li.opportunities-nav-item {
    margin-right: 0;
  }
  #navbar .btn-container {
    font-size: 1.2em;
    position: fixed;
    z-index: 999;
    bottom: 20px;
    right: 20px;
  }
}

@media (max-width: 500px) {
  #navbar {
    flex-direction: column;
  }
}

@media (max-width: 400px) {
  .home-nav-item {
    display: none;
  }
}
/*# sourceMappingURL=Navbar.css.map */
